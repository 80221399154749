import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../views/mainHomePages/LoginPage.vue";
import NoAccess from "../views/mainHomePages/NoAccess.vue";

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () => import(/* webpackChunkName: "LandingPage" */ "../views/mainHomePages/LandingPage.vue"),
    children: [
      {
        path: "/MetricsOverview",
        name: "MetricsOverview",

        component: () => import(/* webpackChunkName: "MetricsOverview" */ "../views/ChildHomePages/MetricsOverview.vue"),
      },
      {
        path: "/Dashboard",
        name: "Dashboard",

        component: () => import(/* webpackChunkName: "Dashboard" */ "../views/ChildHomePages/Dashboard.vue"),
      },
      {
        path: "/Campaigns",
        name: "Campaigns",

        component: () => import(/* webpackChunkName: "Campaigns" */ "../views/ChildHomePages/Campaigns.vue"),
      },
      {
        path: "/Lists",
        name: "Lists",

        component: () => import(/* webpackChunkName: "Lists" */ "../views/ChildHomePages/Lists.vue"),
      },
      {
        path: "/Clients",
        name: "Clients",

        component: () => import(/* webpackChunkName: "Clients" */ "../views/ChildHomePages/Clients.vue"),
      },
      {
        path: "/Products",
        name: "Products",

        component: () => import(/* webpackChunkName: "Products" */ "../views/ChildHomePages/Products.vue"),
      },
      {
        path: "/Roles",
        name: "Roles",

        component: () => import(/* webpackChunkName: "Roles" */ "../views/ChildHomePages/Roles.vue"),
      },
      {
        path: "/Users",
        name: "Users",

        component: () => import(/* webpackChunkName: "Users" */ "../views/ChildHomePages/Users.vue"),
      },
      {
        path: "/Locations",
        name: "Locations",

        component: () => import(/* webpackChunkName: "Locations" */ "../views/ChildHomePages/Locations.vue"),
      },
      {
        path: "/Teams",
        name: "Teams",

        component: () => import(/* webpackChunkName: "AgentGroup" */ "../views/ChildHomePages/Teams.vue"),
      },
      {
        path: "/FieldRepository",
        name: "FieldRepository",

        component: () => import(/* webpackChunkName: "FieldRepository" */ "../views/ChildHomePages/FieldRepository.vue"),
      },
      {
        path: "/OwnForm",
        name: "OwnForm",

        component: () => import(/* webpackChunkName: "OwnForm" */ "../views/ChildHomePages/OwnForm.vue"),
      },
      {
        path: "/OwnData",
        name: "OwnData",

        component: () => import(/* webpackChunkName: "OwnData" */ "../views/ChildHomePages/OwnData.vue"),
      },
      {
        path: "/PBTB",
        name: "PBTB",

        component: () => import(/* webpackChunkName: "PBTB" */ "../views/ChildHomePages/PBTB.vue"),
      },
      {
        path: "/DataModificationRequests",
        name: "DataModificationRequests",

        component: () => import(/* webpackChunkName: "DataModificationRequests" */ "../views/ChildHomePages/DataModificationRequests.vue"),
      },
      {
        path: "/DNDList",
        name: "DNDList",

        component: () => import(/* webpackChunkName: "DNDList" */ "../views/ChildHomePages/DNDList.vue"),
      },
      {
        path: "/DeDuplicates",
        name: "DeDuplicates",

        component: () => import(/* webpackChunkName: "DeDuplicates" */ "../views/ChildHomePages/DeDuplicates.vue"),
      },
      {
        path: "/DispositionCodes",
        name: "DispositionCodes",

        component: () => import(/* webpackChunkName: "DispositionCodes" */ "../views/ChildHomePages/DispositionCodes.vue"),
      },
      {
        path: "/AuditLogs",
        name: "AuditLogs",

        component: () => import(/* webpackChunkName: "DispositionCodes" */ "../views/ChildHomePages/AuditLogs.vue"),
      },
      {
        path: "/Settings",
        name: "Settings",

        component: () => import(/* webpackChunkName: "Settings" */ "../views/ChildHomePages/Settings.vue"),
      },
      // {
      //   path: "/CallAgentReports",
      //   name: "CallAgentReports",

      //   component: () => import(/* webpackChunkName: "CallAgentReports" */ "../views/ChildHomePages/CallAgentReports.vue"),
      // },
      {
        path: "/ProductMaster",
        name: "ProductMaster",

        component: () => import(/* webpackChunkName: "ProductMaster" */ "../views/ChildHomePages/ProductMaster.vue"),
      },
      {
        path: "/CallRecordings",
        name: "CallRecordings",

        component: () => import(/* webpackChunkName: "CallRecordings" */ "../views/ChildHomePages/CallRecordings.vue"),
      },
      {
        path: "/Reports",
        name: "Reports",

        component: () => import(/* webpackChunkName: "CallRecordings" */ "../views/ChildHomePages/Reports.vue"),
      },
      {
        path: "/CurateData",
        name: "CurateData",

        component: () => import(/* webpackChunkName: "CurateData" */ "../views/ChildHomePages/CurateData.vue"),
      },
      // {
      //   path: "/Dashboard-ATDC",
      //   name: "Dashboard-ATDC",
      //   component: () => import(/* webpackChunkName: "Help" */ "../views/ChildHomePages/DashboardATDC.vue"),
      // },
    ],
  },
  {
    path: "/NoAccess",
    name: "NoAccess",
    component: NoAccess,
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: () => import("@/views/ChildHomePages/ReportIssue.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
