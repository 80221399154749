import VueGoogleMaps from "@fawmi/vue-google-maps";
import { Amplify } from "aws-amplify";
import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import store from "./store";

loadFonts();

Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_GM2NoQRZj",
    region: "ap-south-1",
    userPoolWebClientId: "di6oeo3e8eb65d4erc3adfauv",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://mc6qdlfiffethh3csr45pdpilq.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "Report_issue",
        endpoint: " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
      },
    ],
  },
});
createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(VueApexCharts)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_KEY_VOZCARE_PORTAL,
      libraries: "places",
    },
  })
  .mount("#app");
