<template>
  <v-overlay :model-value="overlay">
    <div style="height: 100vh; width: 100vw; display: flex; flex-direction: column; justify-content: center; align-items: center">
      <div>
        <v-img :max-width="maxWidth" src="@/assets/favicon.png"></v-img>
        <v-progress-linear indeterminate color="primary" width="20"> </v-progress-linear>
        <div class="text-white text-center">Please wait...</div>
      </div>
    </div>
  </v-overlay>
</template>
<script>
export default {
  props: {
    overlay: Boolean,
  },
  data: () => ({
    count: 150,
    setInterval: -1,

    maxWidth: "150px",
  }),
  watch: {
    overlay(value) {
      if (value) {
        clearInterval(this.setInterval);
        this.count = 120;
        this.setInterval = setInterval(() => {
          this.count += 10;
        }, 500);
      } else {
        this.count = 120;
        clearInterval(this.setInterval);
      }
    },
    count(value) {
      if (value == 160) {
        this.count = 120;
      }
      if (value) {
        this.maxWidth = `${this.count}px`;
      }
    },
  },
};
</script>
